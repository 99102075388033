




















































import { Component, Prop, Watch } from 'vue-property-decorator';
import VueBaseWidget from '@/utils/widgets/VueBaseWidget';
import { namespace } from 'vuex-class';
import PaginationComponent from '@/components/PaginationComponent.vue';
import { OrderFilter } from '@/graphql/_Filters/OrderFilter';
import ActionType from '@/utils/enums/ActionType';
import Order from '@/models/graphql/Order';
import VueRegisterStoreWidget from '@/utils/widgets/VueRegisterStoreWidget';
import { mixins } from 'vue-class-component';
import LoadingSpinnerComponent from '@/components/LoadingSpinnerComponent.vue';
import OrderCardComponent from '@/components/cards/OrderCardComponent.vue';
import OrderStatus from '@/utils/enums/OrderStatus';

const orderStore = namespace('OrderStore');

@Component({
  components: { OrderCardComponent, LoadingSpinnerComponent, PaginationComponent },
})
export default class OrderListWidget extends mixins(VueBaseWidget, VueRegisterStoreWidget) {
  @orderStore.Action(ActionType.GET_COUNT)
  private countOrders!: (payload: { filter: OrderFilter }) => Promise<void | number | undefined>;

  @orderStore.Action(ActionType.FILTER)
  private filterOrders!: (payload: {
    filter: OrderFilter;
    offset?: number;
    first?: number;
  }) => Promise<void>;

  @orderStore.Getter
  private fetchOrders!: Order[];

  @Prop({
    required: false,
    default: null,
  })
  private entityCode!: string;

  private isLoading = false;

  private doCount = false;

  private first = 10;

  private offset = 0;

  private page = 1;

  private totalCount = 0;

  mounted(): void {
    this.doCount = true;
    this.loadData();
  }

  created(): void {
    this.setDataConfig();
  }

  private onPageChanged(page: number): void {
    window.scrollTo({ top: 0 });
    this.offset = this.first * (page - 1);
    this.page = page;
    this.loadData();
  }

  @Watch('uid')
  private loadData(): void {
    if (this.uid) {
      const statusFilter = [
        OrderStatus.REFUNDED,
        OrderStatus.PAID,
        OrderStatus.WAITING_FOR_PAYMENT,
        OrderStatus.WAITING_FOR_APPROVAL,
        OrderStatus.PENDING,
      ];
      this.isLoading = true;
      if (this.doCount) {
        this.doCount = false;
        this.countOrders({
          filter: {
            exhibitor: { uid: this.uid },
            // eslint-disable-next-line @typescript-eslint/camelcase
            status_in: statusFilter,
          },
        })
          .then((response) => {
            if (response) {
              this.totalCount = response;
            }
          });
      }
      this.filterOrders({
        filter: {
          exhibitor: { uid: this.uid },
          // eslint-disable-next-line @typescript-eslint/camelcase
          status_in: statusFilter,
        },
        offset: this.offset,
        first: this.first,
      }).then(() => {
        this.isLoading = false;
      });
    }
  }

  private get uid(): string | null {
    let r = this.entityCode;
    if (this.entityCode) {
      const matches = this.entityCode.match(/(%[a-zA-Z-_]+%)/gs);
      if (matches) {
        matches.forEach((m) => {
          const prop = m.replaceAll('%', '')
            .trim();
          r = r.replaceAll(m, this.$route.params[prop]);
        });
        return r;
      }
    }
    return this.entityCode;
  }
}
